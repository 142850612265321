<template>
  <Line
    v-if="data.length > 0 && labels.length > 0"
    id="number-of-worked-hours"
    :options="chartOptions"
    :data="chartData"
  />
</template>
<script>
import { Line } from "vue-chartjs";

export default {
  components: { Line },
  props: {
    data: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.data,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          tooltip: this.setTooltip(),
          title: {
            display: true,
            text: "Aantal gewerkte uren (Productie & KS)",
            font: {
              size: 20,
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Dag",
            },
          },
          y: {
            title: {
              display: true,
              text: "Uren",
            },
          },
        },
        layout: {
          padding: {
            top: 20,
          },
        },
      };
    },
  },
  methods: {
    setTooltip() {
      return {
        boxPadding: 4,
        callbacks: {
          title: (item) => {
            return `${item[0].dataset.label} (${item[0].label})`;
          },
          label: (item) => {
            return [`Aantal gewerkte uren: ${this.data[item.datasetIndex].data[item.dataIndex]}`];
          },
        },
      };
    },
  },
};
</script>
